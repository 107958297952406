.cos-mobile{
    $border-color: rgba(0, 0, 0, 0.1);

    width: 100%;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid $border-color;
    border-bottom-width: 4px;
    //padding: 16px;

    font-size: 1.05em;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);

    .greyed{
        opacity: 0.6;
    }

    .cos-title{
        padding-bottom: 14px;
        border-bottom: 1px solid $border-color;
        //color: white;
        //background-color: $secondary;
        font-weight: 500;
        color: $success;
        padding: 16px;
        background-color: $success;
        color: white;
    }

    .cos-body{
        padding: 16px;
        font-size: 0.95em;
    }

    .cos-list-item{
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 0.9em;
    }

    .cos-text{
    }

    .cos-continue{
        font-weight: 700;
        float: right;
        color: #F36D21;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 6px;
        color: white;
        top: -6px;
        right: -5px;
        position: relative;
        background-color: #F36D21;
        cursor: pointer;

        &:hover{
            filter: brightness(0.9);
        }
    }

    .cos-hidden{
        margin-top: 20px;
        border-top: 1px solid $border-color;

        .cos-hidden-title{
            padding-top: 16px;
            color: $primary;
            cursor: pointer;

            &:hover{
                filter: brightness(0.9);
            }

            .cos-chevron{
                float: right;
                display:none;
                margin-right: 8px;
                &[data-show='true']{
                    display:inline-block;
                }
            }
        }
        .cos-hidden-body{
            height: 0px;
            overflow-y: hidden;
            
            >div{
                padding-top: 8px;
            }
            &[data-show='true']{
                height: auto;
            }
        }
    }

    .cos-includes{
        text-transform: uppercase;
        font-size: 0.8em;
        margin-top: 16px;
        opacity: 0.5;
    }

    .cos-icon{
        margin-right: 10px;
    }
    
    .help-icon-table{
        position: relative;
        float: right;
        //transform: scale(0.65) translate(2px, -2px);
    }
}