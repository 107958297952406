header{
    background-color: $white;

    @include media-breakpoint-down(sm) { 
        height: 78px;
    }
    @include media-breakpoint-up(md) { 
        height: 116px;
    }

    .logo{
        float: left;
        height: 100%;

        @include media-breakpoint-down(sm) { 
            padding-top: 14px;
            padding-bottom: 20px;
            padding-left: 14px;
            padding-right: 10px;
        }

        @include media-breakpoint-up(md) { 
            padding-bottom: 24px;
            padding-top: 16px;
            padding-left: 24px;
            padding-right: 26px;
        }
    }
    .form-name{
        display:inline;
        color: #48AF35;
        font-family: lato, sans-serif;
        position: relative;
        letter-spacing: -0.5px;
        font-weight: 600;
        border-left: 2px solid #48AF35;
        letter-spacing: -0.05em;


        @include media-breakpoint-down(sm) { 
            font-size: 17px;
            top: 37px;
            left: -3px;
            padding-left: 9px;
        }

        @include media-breakpoint-up(md) { 
            font-size: 30px;
            top: 55px;
            left: -14px;
            padding-left: 14px;
            border-left-width: 4px;
        }
    }


}
