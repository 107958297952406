body{
    width: 100vw;
    overflow-x: hidden;
}


.app{
    //height: 1px; // IE11 Hack
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $white;
}