
.PriceHover{
    //width: 200px;
    
    text-align: center;
    //margin-bottom: 10px;
    //margin-top: 10px;

    z-index: 99;
    $percent: 30px;

    @include media-breakpoint-down(md) {
        display:none;
    }


    .PriceHoverContainer{
        width: 170px;
        height: 102px;
        //margin-top: 10px;


        @include media-breakpoint-down(sm) {
            width: 140px;
            transform: scale(0.7) translate(52px, 10px);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }


        border-radius: 8px;
        background-image: linear-gradient($secondary $percent, $success $percent);
        filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.45));

        transform: scale(0.9) translate(10px, 10px);
        
        .PriceHoverHeader{
            padding: 6px;
            font-size: 0.8em;
            font-weight: 700;
            color: white;
        }

        .PriceHoverBody{
            padding-top: 4px;
            padding-bottom: 20px;
            color: white;

            @include media-breakpoint-up(md) {
                margin-top: -12px;
            }
        }

        .PriceHoverCaption{
            font-size: 0.6em;
            margin-top: -11px;
        }

        [data-bottom='true']{
            top: 1.95em !important;
        }
    }

}
