@include media-breakpoint-up(md) { 
    footer.footer-mobile{
        display: none;
    }
}

footer.footer-mobile{
    background-color: $gray;
    color: white;
    padding: 1em;

    .footer-icons{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        border-bottom: 2px solid white;
        padding-bottom: 1em;

        img{
            height: 30px;
            width: auto;
        }
    }

    .footer-links{
        padding: 1.5em;

        @media only screen and (max-width: 660px) {
            padding: 1.0em;
            padding-left: 0;
            padding-right: 0;

            a{
                width: 50% !important;
                font-size: 0.9em;
            }
        }

        a{
            float: left;
            color: white;
            font-weight: 300;
            width: 33%;
            margin-top: 0.2em;
            margin-bottom: 0.2em;
        }
    }

}