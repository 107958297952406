.section-updatable{
    text-align: center;

    background-image: url('../../assets/flex/bg.png');
    background-position: center;

    color: white !important;

    .section-updateable-blur-container{
        &[data-dirty='true']{
            filter: blur(10px);
            pointer-events: none;
        }
    }


    &[data-loading="true"]{
        .price, .dropdown{
            filter: blur(10px);
        }
    }

    .custom-button{
        .icon-right{
            width: 0;
            margin-left: 0;
        }
    }

    .section-updatable-view-premium-breakdown{
        opacity: 0.8;
        font-size: 0.7em;
    }

    [data-hide-continue-small='true']{
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .section-updatable-price-container{
        max-width: 100%;
        overflow: hidden;
        pointer-events: none;
    }


    a, .section-updatable-charges{
        color: white;
        opacity: 0.85;
    }
    
    .section-updatable-charges{
        font-size: 0.7em;
        opacity: 0.85;
    }

    .btn-primary{
        filter: drop-shadow(0px 4px 0px darken($primary, 25%));
    }

    .button-container{
        margin-top: 1.5em;
        @include media-breakpoint-up(md) {
            margin-bottom: 1.5em;
        }
    }

    select{
        font-size: 0.8em;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 250px;
    }

    .section{
        background-color: transparent !important;
        //overflow-x:hidden;

        .loading-indicator{
            position: relative;
            top: 0px;
        }
    }

    a{
        font-size: 0.8em;
    }

    .price{
        filter: drop-shadow(0px 3px 2px rgba(0,0,0,0.4));
        transform: scale(1.5);
        position: relative;
        left: 15px;

        margin-bottom: 4px;
        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }
    }

    .section > .loading-indicator{
        max-width: 340px;
        margin: auto;
    }

    .btn{
        width: 100%;
        font-size: 1em;
    }
}
