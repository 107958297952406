.quote{
    font-weight: 700;
    color: $primary;
    font-size: 1.2em;
    flex-grow: 1;
}

.title-container{
    .quote{
        font-size: 1em;
    }
}