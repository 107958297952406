.breadcrumbs{
    font-weight: 700;

    padding-top: 1.7em;
    padding-bottom: 0.5em;

    font-weight: 500;
    white-space: nowrap;

    .breadcrumbs-item{
        &:after{
            content: ">";
            padding-left: 10px;
            padding-right: 10px;
        }
        &:last-child:after{
            content: none;
        }
    }
}