.size-33{
    @include media-breakpoint-up(md) {
        width: 50%;
    }
}

.size-66{
    @include media-breakpoint-up(md) {
        width: 50%;
    }
}

.size-50{
    @include media-breakpoint-up(md) {
        width: 50%;
    }
}

.for-desktop{
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.for-mobile{
    @include media-breakpoint-up(md) {
        display: none;
    }
}