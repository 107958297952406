.button-container{
    margin-top: 2em;

    .btn{
        margin-right: 0.5em;
    }

    @include media-breakpoint-down(sm) {
        .btn{
            width: 100%;
            margin-right: 0;
            margin-bottom: 0.5em;
        }
    }
}