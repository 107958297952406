.final-price-block{

    @include media-breakpoint-up(md){
        display: none;
    }

    .final-price-block-text{
        font-size: 1.8em;
        text-align: center;
        margin-top: 0.5em;
        font-weight: 900;
        color: $success;
    }
    
    .price{
        color: $white;
        background-color: $purple;
        margin-bottom: 0;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .final-price-triangle-spacer{
        height: 0;
        position: relative;
        top: 0;
        left: 50%;
        text-align: center;

        .final-price-triangle{
            $width: 12px;
            $height: 12px;

            position: relative;
            left: -$width;
            top: -$height + 1px;
            width: 0;
            height: 0;
            border-left: $width solid transparent;
            border-right: $width solid transparent;
            border-bottom: $height solid $gray;
        }
    }


    .final-price-message{
        text-align: center;
        background: $gray;
        color: white;
        font-size: 1.2em;
        font-weight: 800;
        padding: 0.7em;

        @include media-breakpoint-down(xs){
            font-size: 1em;
        }
    }



}