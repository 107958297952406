.keyline{
    height: 4px;
    width: 100%;
    background-color: $primary;

    margin-top: 50px;
    margin-bottom: 40px;
}

.app-brand-chu{
    .keyline{
        &:after {
            content: "";
            display: block;
            height: 100%;
            width: 40px;
            background-color: $progress-bar-color;
        }
    }
}