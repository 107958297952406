.contact{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: $footer-strip-color;
    padding: 1.1em;
    padding-left: 2.8em;
    padding-right: 2.8em;
    color: white;

    a{
        color: $white;
    }
    
    .contact-icon{
        margin-left: 0.6em;
    }

    .contact-phone{
        margin-right: 0.3em;
    }

    .footer-phone{
        font-size: 1.5em;
        > div {
            display: inline;
        }

        small{
            font-size: 0.7em;
        }
    }

    .footer-linkedin{
        display: flex;
        align-items: center;

        svg{
            margin-left: 20px;
        }
    }

    .contact-linkin{
        display: none;
    }

    @include media-breakpoint-down(sm) { 
        justify-content: center;
        padding-left: 0;
        padding-right: 0;

        .footer-phone{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .contact-linkin{
            display: inline;
        }

        .footer-linkedin{
            display: none;
        }
        .footer-phone{
            font-size: 1.28em;
        }
    }

}