.button-menu{
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;

    &[data-singular="true"]{
        .button-menu-icon{
            display: none;
        }
        button{
            pointer-events: none;
        }
    }

    .dropdown-toggle, .dropdown-item{
        outline: none;
        color: $secondary !important;
        box-shadow: none !important;

        &:active{
            background-color: $gray-300 !important;
        }
    }

    .button-menu-icon{
        color: $primary;
        width: 0;
        right: 10px;
        position: relative;
    }

    .dropdown-toggle::after{
        display: none;
    }

 
    .dropdown-menu{
        width: 100% !important;
        max-width: 100%;
        padding: 0;
        border: 1px solid $gray-500;
        //filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.3));
        
        button{
            width: 100% !important;
            max-width: 100%;
            text-align: center;
            height: 60px;

            &:hover{
                background-color: $gray-200;
            }
            
        }
    }

    .dropdown-item{
        width: 100% !important;
    }

}