.buttons{
    display:flex;
    flex-direction: row;

    margin-top: 2em;

    @include media-breakpoint-down(sm) { 
        margin-top: 1.5em;
    }

    .btn{
        margin-left: 4px;
        margin-right: 4px;

        .icon-right{
            padding-left: 12px;
        }

        &:first-child{
            margin-left: 0;
        }
    }

    
}