
.final-price-payment{
    margin-top: 1em;
    margin-bottom: 1em;
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .price-card-payment-schedule{
        color: $gray;
        font-weight: 800;
        font-size: 1.5em;
        width: 100%;
        text-align: center;
    }

    @include media-breakpoint-down(sm) { 
        display: inherit;
        margin-top: inherit;
        margin-bottom: inherit;
    }

    .btn-buy-now{
        min-width: 160px;
        font-weight: 900 !important;
        white-space: nowrap;
    }

    .custom-select{
        font-weight: 700;
    }

    .colour-block-container{
        text-align: center;

        .price{
            background-color: $purple;
            margin-top: calc(-0.4em + 1px);
            margin-left: calc(-0.4em + 1px);
            margin-right: -0.4em;
        }

        .discount-message{
            margin-left: calc(-1.05em + 1px);
            margin-right: calc(-1.05em);
            background-color: $orange;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    
    
    .price-card{
        display: flex;
        align-items: center;
        justify-content: space-between; 

        @include media-breakpoint-down(xs) { 
            display: inherit;
        }
    }

    .final-price-buttons-container{
        width: 100%;
        @include media-breakpoint-down(sm) { 
            width: 100%;
        }

        .custom-select{
            width: 48%;
            height: 67px;
            color: $gray;
            font-size: 1.2em;

            @include media-breakpoint-down(xs) { 
                height: 62px;
            }

            @include media-breakpoint-down(xs) { 
                height: inherit;
                width: 100%;
            }
        }

        .btn{
            @include media-breakpoint-down(xs) {
                width: 100%;
                margin-top: 6px;
            }
        }


        .price-card-info{
            text-align: center;
            flex-basis: length;
            flex: none;
            font-size: 0.8em;
            margin-top: 1.0em;  
            line-height: 1.4em;

            b{
                font-size: 1.35em;
            }
        }
    
    }
}
