.cover-option-table{

    .greyed{
        opacity: 0.6;
    }

    .optional{
        opacity: 0.7;
        font-weight: 700;
    }

    td:first-child{
        width: 34%;
        text-align: left;
    }

    td{
        width: 22%;
        text-align: center;
    }

    td:nth-child(3){
        background-color: rgba(255, 255, 255, 0.6);
        
    }

    tr:first-child, tr:nth-child(2), tr:nth-child(3){
        td, th{
            border-top: none;
        }
    }

    tr:nth-child(n+3){
        font-size: 0.7em;
        padding-left: 0px;
        padding-right: 0px;
        
    }

    tr:nth-child(3){
        font-size: 0.7em;
        opacity: 0.7;
        td{
            padding-top: 0;
            padding-bottom: 20px;
        }
    }

    tr:nth-child(2){
        td{
            font-weight: 700;
        }
    }

    tr:first-child{
        td:nth-child(3){
            background-color: $success;
            color: white;
            font-size: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            font-weight: 700;
        }
    }

    

}