.ribbon{
    height: 90px;
    background-position: right;
    background-repeat: no-repeat;
    font-weight: 900;
    
    position: relative;
    left:-32px;
    margin-bottom: 5px;
    white-space: nowrap;

    @include media-breakpoint-down(sm) { 
        left:-27px;
    }


    .ribbon-text-container{
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 32px;
        width: 83%;
        height: 100%;
    }
    

    .ribbon-corner-spacer{
        width: 0px;
        height: 0px;
        position: relative;
        top:100%;
        
        .ribbon-corner{
            background-image: url('../../assets/common/ribbon-corner.svg');
            width: 12px;
            height: 12px;
        }
    }
}