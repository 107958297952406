.title-container{

    // Variation 1
    //color: $white;
    //background-color: $gray;
    //border-bottom: 1px solid $white;
    //padding-bottom: 18px;
    //padding-top: 18px;
    //margin-bottom: 2.5em;

    // Variation 2
    padding-top: 1.7em;
    padding-bottom: 0.5em;
    
    font-weight: 500;

    white-space: nowrap;

    .back-button{
        font-weight: 600;
    }

    .title-text-container, .line{
        display: none;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 17px;
        padding-bottom: 10px;
    }

   
    .inner-container{
        display: flex;
        cursor: pointer;

        .icon {
            padding-right: 10px;
        }
    }

    .icon-container{
        margin-right:18px;

        @include media-breakpoint-down(sm) { 
            margin-right:12px;
        }
    }

    .title-text-container{
        margin-left: 18px;
        @include media-breakpoint-down(sm) { 
            margin-left:12px;
        }
    }

    .line{
        position: relative;
        top: -1px;
        font-weight: 900;
    }

}