.cover-options{
    text-align: left;
    font-size: 0.9em;

    .grid-lg{
        gap: 12px 24px !important;
    }

    .cover-option{
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        line-height: 1.35em;
        
        .cover-option-top{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0.65em;

            @include media-breakpoint-down(sm) { 
                margin-top: 0.85em;
            }
    
            .cover-option-title{
                font-weight: 600;
                //font-size: 1.2em;
            }

            .cover-option-edit{
                color: $primary;
                position: relative;
                top: 0.55em;
                cursor: pointer;

                &:hover{
                    .cover-option-edit-icon{
                        opacity: 1;
                    }
                }

                .cover-option-edit-icon{
                    margin-right: 0.4em;
                    opacity: 0.65;
                }
                .cover-option-edit-label{
                    text-decoration: underline;
                }
            }
        }


    }

}