#date-picker{
    margin-top: 2em;

    .datepicker.datepicker-inline{
        width: 100%;
        font-weight: 600;
        color: $gray;

        .table-condensed{
            width: 100%;
            background-color: $gray-200;
            border: 1px solid $gray-500;
            .next.disabled, .prev.disabled{
                display: inherit;
            }

            .next, .prev{
                font-size: 0;
            }

            .next:after{
                font-size: 16px;
                font-family: "Font Awesome 5 Free";
                content: "\f054"
            }

            .prev:after{
                font-size: 16px;
                font-family: "Font Awesome 5 Free";
                content: "\f053"
            }

            thead {
                >tr:first-child{
                    display: none;
                }

                tr:nth-child(2){
                    color: white;
                    background-color:$gray;
                    th{
                        background-color:$gray;
                    }
                }

                th{
                    background-color: $gray-200;
                }
            }

            tfoot{
                display: none;
            }
            
            tr{
                height: 60px;
                @include media-breakpoint-down(sm) {
                    height: 48px;
                }
            }

            th, td{
                border: 1px solid $gray-500;
                background-color: $white;
                border-radius: 0;
            }

            .old.day, .new.day{
                color: $gray-500;
            }

            .disabled{
                background-color: $gray-200;
                color: $gray-400;
            }

            .active{
                background-color: $success;
            }

            thead{
                tr:nth-child(1), tr:nth-child(2){
                    th, td{
                        border: none;
                    }
                }
            }
        }
    }

}