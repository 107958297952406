.fill-button{
    width: 100%;
    height: 100%;

    line-height: 1.3em;
    padding: 0;

    .btn-success .help-icon{
        color: white !important;
    }

    
    .fill-button-contents{
        width: 100%;
        //height: 100%;
        display: flex;
        
        align-items: center;

        .fill-button-info{
            position: relative;
            top: -3px;

            @include media-breakpoint-up(md) {
                display: none;    
            }
        }

        flex-direction: column;

        @include media-breakpoint-down(sm) {
            flex-direction: row; 
            justify-content: space-between;
            text-align: left;
            .fill-button-info{
                display: block;
            }
        }

        padding: 0.6em;
        padding-top: 0;
        padding-bottom: 0;

        @include media-breakpoint-down(sm) { 
            padding: 1.0em;
        }

        .fill-button-label{
            text-align: center;
            width: 100%;
            word-break: break-word;
            @include media-breakpoint-down(sm) { 
                text-align: left;
            }
        }

        .fill-button-info-body{
            font-weight: 500;
            font-size: 0.60em;
            line-height: 125%;
            margin-top: 0.2em;
            width: 100%;

            @include media-breakpoint-down(sm) { 
                display: none;
            }
        }

        .fill-button-info{
            opacity: 0.85;
            color: $primary;
        }

        @include media-breakpoint-down(xs) { 
            padding-left: 1.8em;
            padding-right: 1.8em;
        }
    }
    

    .icon-container{
        width: 0;
        height: 0;
        position: relative;
        top: 2px;
        left: calc(100% - 20px);
        opacity: 0.5;
    }

}