body{
    .step{
        font-size: 1.1em;
    }
}


.btn-primary{
    color: white !important;
    text-decoration: none !important;
}

@include media-breakpoint-down(md) {
    body{
        .step{
            font-size: 1em;
        }
    }
}

@include media-breakpoint-down(sm) { 
    body{
        font-size: 0.9em;
        .step{
            font-size: 1em;
        }
        h1{
            font-size: $h1-font-size * 0.6;
        }
        h2{
            font-size: $h2-font-size * 0.6;
        }
        h3{
            font-size: $h3-font-size * 0.6;
        }
        h4{
            font-size: $h4-font-size * 0.6;
        }
        h5{
            font-size: $h5-font-size * 0.6;
        }
        h6{
            font-size: $h6-font-size * 0.6;
        }
    }
}


h1, h2, h3, h4, h5, h6{
    font-weight: $headings-font-weight;
}

h1{
    margin-top: 2em;
}

h4 + h4{
    margin-top: 1em;
}

input{
    color: $gray !important;
}

h2{
    margin-top: 0.4em;
    margin-bottom: 0.6em;
    @include media-breakpoint-down(sm) { 
        margin-bottom: 1em;
    }

}

h3{
    margin-top: 0.9em;
    margin-bottom: 0.7em;

    +.section{
        margin-top: -0.7em;
    }
}



@include media-breakpoint-down(sm) { 
    ul, ol{
        padding-left: 1.4em;
    }
}


.text-question{
    margin-bottom: 18px;
    font-weight: 600;
}

.text-faded{
    opacity: 0.7;

    a{
        opacity: 1.0;
    }
}
