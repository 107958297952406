.icon-card{
    text-align: center;

    .icon-card-icon{
        height: 33px;
        text-align: center;
        margin-bottom: 0.45em;

        @include media-breakpoint-down(sm){
            height: 26px;
            margin-bottom: 0.4em;
        }

        @media only screen and (max-width: 340px) {
            display: none;
        }

        img{
            width: auto;
            height: 100%;
        }
    }

    .icon-card-title{
        font-weight: 600;
        margin-bottom: 0.25em;
        line-height: 1.3em;
    }

    .icon-card-description{
        font-size: 0.7em;
        line-height: 1.3em;
    }

    
}