.price{
    font-weight: 800;
    font-size:2.5em;
    //margin-bottom: 0.325em;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    letter-spacing: -0.04em;

    &.large{
        font-size: 4.0em;

        @include media-breakpoint-down(md){
            font-size: 2.6em;
        }

        @include media-breakpoint-down(sm){
            font-size: 4.0em;
        }

        @include media-breakpoint-down(xs){
            font-size: 4.0em;
        }
    }

    .price-icon, .price-cents{
        font-size: 0.4em;
        position: relative;
        top: 0.875em;
        letter-spacing: 0.02em;
        font-weight: 900;
        text-align: left;
        line-height: 0.95em;
        //left: -0.1em;
    }

    .price-cents{
        left:0.2em;
        padding-right: 1em;

        span{
            opacity: 0.9;
            font-weight: 600;
        }
    }
}