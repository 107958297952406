.checkbox{
    .custom-control-label::before, 
    .custom-control-label::after {
        width: 1.5rem;
        height: 1.5rem;
    }

    .checkbox-label{
        position: relative;
        top: 5px;
        left: 8px;
    }

    margin-bottom: 20px;

    &.is-invalid{
        .input-errors{
            .input-caption.invalid-feedback{
                display: block;
                margin-left: 0.6em;
                margin-top: 0.6em;
            }
        }
    }

}