@include media-breakpoint-down(sm) { 
    footer.footer-desktop{
        display: none;
    }
}


footer.footer-desktop{

    text-align: center;

    .btn{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding-bottom: 2px;
        padding-left: 22px;
        padding-right: 22px;
        padding-top: 8px;

        .icon {
            padding-left: 6px;
        }
    }

    .footer-content{
        color: $white;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        
        &.footer-bottom{
            background-color: $gray;
            padding: 1.75em;
            padding-left: 0.5em;
            padding-right: 0.5em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            font-weight: 200;

            > *{
                flex: 1;
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
           
            .footer-logos{
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include media-breakpoint-down(lg) {
                    img:nth-child(1){
                        width: 80% !important;
                    }
                    img:nth-child(2){
                        width: 55% !important;
                    }
                }
                
                img:nth-child(1){
                    width: 230px;
                    height: auto;
                    position: relative;
                    top: 0.4em;
                    left: 1.75em;
                }
                img:nth-child(2){
                    width: 160px;
                    height: auto;
                    position: relative;
                    top: 8em;
                    left: 1.75em;
                }
            }

            .footer-links{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                h3{
                    font-size: 1.1em;
                }
                a{
                    display: block;
                    color: white;
                    font-size: 0.9em;
                    line-height: 1.6em;
                }
            }

            > :first-child, > :last-child{
                flex:1.6;
            }
        }
    }

}
