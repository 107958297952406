.step{
    min-height: 400px;
    width: 100vw;
    //overflow-x: hidden;
    min-height: 100%;
    flex-grow: 1;
    padding-bottom: 3em;
    background-color: $gray-200;

    h2 > *:not(p){
        margin-bottom: 0.75em;
    }


    a{
        text-decoration: underline;
    }

}