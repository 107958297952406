.grid-lg {
    display: -ms-grid;
    display: grid;
    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.grid-sm {
    display: -ms-grid;
    display: grid;
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}


// HACK: IE11
.grid-cell {
    padding: 2px;
}

@supports (display: grid) {
    .grid-cell {
        padding: 0px;
    }
}