.number-picker{
    margin-top: 28px;

    .fill-button-contents{
        padding-left: 0;
        padding-right: 0;
        .fill-button-label{
            text-align: center;
        }
    }
}