.show-legal-button{
    text-align: center;
    background-color: $gray-200;

    .btn{
        width: 100px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        text-decoration: underline;
        padding-top: 6px;
        padding-bottom: 4px;
        padding-left: 4px;
        padding-right: 0px;
        font-size:1.1em;
        font-weight: 600;

        border-bottom: 1px solid $gray-400;
        
        opacity: 0.85;

        span{
            margin-left: 4px;
        }
    }

    // HACK: HIDE FOR IE11
    display: none;
    @supports (display: grid) {
        display: block;
    }

}