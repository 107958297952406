.btn{
    font-weight: 600 !important;
}

.custom-button{
    font-weight: 500;
    font-size: 1.2em;

    padding-top: 0.975em;
    padding-bottom: 0.975em;
    padding-left: 1.8em;
    padding-right: 1.8em;

    &.block{
        width: 100%;
    }

    &.size-small{
        font-size: 0.9em;
    }

    
    .icon-right{
        margin-left: 0.5em;
        @include media-breakpoint-down(sm) { 
            width: 0px;
        }
    }

    .icon-left{
        margin-right: 0.5em;
        @include media-breakpoint-down(sm) { 
            width: 0px;
        }
    }
}