.section-flexi{
    display:flex;
    width: 100%;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;

    cursor: pointer;

    &[data-compulsory='true']{
        cursor: inherit;
        pointer-events: none;

        .section-flexi-icon{
            color: $gray-900;
        }
    }


    .section-flexi-icon{
        color: $primary;  
        display: none;
        margin-right:20px;
        
        &[data-show='true']{
            display:inline;
        }
    }

    &:hover{
        .section-flexi-icon{
            filter: brightness(0.8);
        }
    }

    &:active{
        .section-flexi-icon{
            filter: brightness(1.2);
        }
    }

    @include media-breakpoint-up(md){
        font-size: 0.85em;
    }

    &:last-child{
        border-bottom: none;
        padding-bottom: 0;
    }
   
    div:last-child{
        .section-flexi-body{
            opacity: 0.9;
            font-size: 0.9em;
            
            @include media-breakpoint-down(md) {
                font-size: 0.85em
            }
        }
       
    }

    .section-flexi-title{
        margin-top: 0;
        margin-bottom:8px;
        font-size: 1.2em;
        font-weight: 700;
        line-height: 1.2em;

        span{
            opacity: 0.8;
        }

        @include media-breakpoint-down(md) {
            font-size: 1em
        }
    }

}