.vertical-divider{

    @include media-breakpoint-up(md) {
        display:flex;

        .vertical-divider-division > * {
            margin-top: 0;
            margin-bottom: 0;
        }
        
        .vertical-divider-division:not(:last-child){
            padding-right: 20px;

            &.large{
                border-right: 4px solid $gray;
            }
            &.small{
                border-right: 2px solid $gray;
            }
        }

        .vertical-divider-division:not(:first-child){
            padding-left: 20px;
        }

    }
     
}