.button-multi-select{
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    .btn{
        //border-radius: 6px;
        
        //box-shadow: 0px 6px 0px $gray-300, 0px 6px 8px rgba(0, 0, 0, 0.05);
        
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
        
        font-weight: 500;
        font-size: 1.1em;
    }
   
}


.btn-outline-secondary{
    background-color: white !important;
    border-color: $gray-400 !important;

    &:hover{
        background-color: $gray-200 !important;
        color: $gray;
    }

    &:active{
        background-color: $gray-300 !important;
        color: $gray !important;
    }

}


div[data-invalid='true'] {
    .button-multi-select{
        .btn-outline-secondary{
            border-color: red !important;
        }
    }
    label{
        display: block !important;
        margin-top: -10px;
    }
}
