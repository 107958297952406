.icon-grid{
    text-align: left;
    line-height: 1.15em;
    font-size: 0.9em;

    .grid-lg, .grid-sm {
        text-align:left !important;
    }

    .icon-grid-entry{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items:center;

        .icon-grid-icon img{
            width: 30px;
            height: auto;
            margin-right: 14px;
            position: relative;
            top: -2px;
        }

    }

}