.sticky-price{
    @include media-breakpoint-up(lg){
        position: sticky;
        top: 24px;
    }
}

.sections{
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;

    >h3{
        background-color: $secondary;
        color: white;
        padding: 0.8em;
        margin-bottom: 0.65em;

        @include media-breakpoint-down(md){
            margin-bottom: 0.9em;
        }

        &:first-child{
            margin-top: 0;
        }
    }
    
    .section{
        &.section-price{
            @include media-breakpoint-down(md){
                position: sticky;
                top: 11px;
                z-index: 99;
            }
        }

        background-color: $gray-100;
        border: 1px solid $gray-300;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 0.3em;

        &.clickable{
            cursor: pointer;
            h2{
                //text-decoration: underline;
                //color: $primary;
            }
            &:hover{
                background-color: $gray-200;
            }
        }

        &.collapsable{
            .section-header{
                cursor: pointer;
                //text-decoration: underline;
                &:hover{
                    background-color: $gray-200;
                }
            }
        }

        .section-header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.section-header-price{
                .price{
                    position: relative;
                    top: 2px;
                }
            }

            &[data-color='primary']{
                background-color: $primary;
                color: white;
                h2{
                    color: white;
                }
            }

            &[data-color='secondary']{
                background-color: $secondary;
                color: white;
                h2{
                    color: white;
                }
            }

            &[data-color='success']{
                background-color: $success;
                color: white;
                h2{
                    color: white;
                }
            }


            .section-header-icon{
                padding-right: 1.4em;
                color: $primary;
                position: relative;
                top: 3px;
                
                .show-text{
                    font-weight: 700;
                    margin-right: 0.5em;
                }
            }

        }

        @include media-breakpoint-down(sm) { 
            //padding: 1.0em;
        }

        h2{
            color: $gray;
            font-size: 1.2em;
            margin-top: 0;
            margin-bottom: 0;
            padding: 1em;

            @include media-breakpoint-down(sm) {
                padding: 1em;
                padding-bottom: 0.9em;
            }

        }

        .section-children {
            &.show{
                display: flex;
            }
            padding: 1.2em;
            flex-grow: 1;
            flex-direction: column;
            
            @include media-breakpoint-down(sm) {
                padding: 1em;
            }

            > * {
                flex-grow: 1;
            }

            &[data-color='primary']{
                background-color: $primary;
            }

            &[data-color='secondary']{
                background-color: $secondary;
            }

            &[data-color='success']{
                background-color: $success;
            }
        }


    }

    .section-header ~ .section-children{
        padding-top: 0 !important;
    }

    .section:first-child > h2{
        margin-top: 0;
    }

}