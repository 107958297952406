.installments-table{
    margin-bottom: 20px;
    font-size: 0.9em;
    
    th{
        vertical-align: top;
    }

    th:nth-child(1){
        font-weight: 400;
        padding-right: 10px;
    }

    th:nth-child(2){
        font-weight: 600;
    }

}