.chu-awards{
    img{
        width: 100%;
        height: auto;
        margin-top: 1em;
    }
    
    .chu-award-text{
        //background-color: red;
        text-align: center;
        
        @include media-breakpoint-down(sm) { 
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: left;
        }
        

        .chu-award-icon{
            //display: none;
            text-align: center;
            margin-right: 0.9em;
           
            @include media-breakpoint-down(sm) { 
                display: inherit;
            }

            img{
                width: 40px;
                height: 40px;
            }
            margin-bottom: 1em;
            //margin-right: 2em;
            //display: none;
        }

        .chu-award-header{
            font-weight: 700;
            padding-bottom: 0.2em;
            font-size: 1.0em;
            line-height: 1.4em;

            @include media-breakpoint-down(xs) { 
                font-size: inherit;
                //font-size: 0.8em;
            }
        }

        .chu-award-body{
            font-size: 0.8em;
        }
    }

}