.estimate-price-container{
    margin-top: 1em;
    margin-bottom: 6px;
    
    .estimate-price-tabs{
        width: 100%;
        display: flex;

        @include media-breakpoint-up(lg) { 
            display: none;
        }

        .estimate-price-tab{
            background-color: $primary;
            color: white;
            font-weight: 700;
            padding: 4px;
            padding-left: 17px;
            padding-right: 17px;
            margin-right: 4px;
            margin-left: 1px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            cursor: pointer;

            &[data-show='true']{
                background-color: $gray-700;
            }

            &:hover{
                filter: brightness(0.9);
            }
        }
    }

    .estimate-price{
        text-align: center;
        position: relative;

        .price{
            .price-cents{
                padding-right: 0;
            }
        }

        &.estimate-price-narrow{
            left: 10%;
            width: 80%;
        }

        //@include media-breakpoint-up(md) {
            display: flex;
        //}

        .estimate-price-icon{
            margin-bottom: 18px;
        }
        
        .estimate-price-option{
            border: 1px solid $gray-300;
            background-color: white;
            display: flex;

            &[data-floating="true"]{
                box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
            }

            @include media-breakpoint-down(md) {
                display: none;

                &[data-selected='true']{
                    display: flex;
                }
            }
            
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            justify-content:space-evenly;

            .btn{
                @include media-breakpoint-down(md) { 
                    display: none;
                }
            }

            .best-value{
                position: absolute;
                top: 0;
                right: 0;
                transform-origin: 100% 0%;
                transform: scale(0.45);
            }


            &.zoomed{
                box-shadow: 0px 9px 16px rgba(0, 0, 0, 0.15);

                @include media-breakpoint-down(md) {
                    box-shadow: none;
                }

                @include media-breakpoint-up(lg) {
                    z-index:1000;
                    transform: scale(1.05, 1.05);
                    position: relative;
                    top: -8px;

                    &[data-floating="true"]{
                        top: 4px;
                    }
                }
            }

            .estimate-price-option-title{
                font-weight: 700;
                padding: 0.5em;
                flex-grow: 0;
                background-color: $secondary;
                color: $white;
            }


            .estimate-price-option-body{
                padding: 1.5em;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                
                @include media-breakpoint-down(sm) {
                    padding-bottom: 2.5em;
                }

                .estimate-price-option-discount{
                    font-size: 0.9em;
                    color: $primary;
                    font-weight: 600;
                    margin-bottom: 0.5em;
                }
                
                .estimate-price-option-description{
                    line-height: 1.3em;
                    font-size: 0.75em;  
                    margin-top: -10px;
                    flex-grow: 1;
                    opacity: 0.8;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }
                }

                .btn{
                    margin-top: 1em;
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                }

            }
            

        }

    }

}