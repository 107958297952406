.loading-indicator{
    position: relative;

    &.fixed{
        .loading-indicator-icon-container{
            &.is-loading{
                position: fixed;
                width: 100vw;
                height: 100vh;
                z-index: 100000;
                background-color: rgba(255, 255, 255, 0.8);
            }
        }
    }

    .loading-indicator-icon-container{
        display: none;
        position: absolute;

        &.is-loading{
            display: flex;
            width: 100%;
            height: 100%;
            z-index: 1000;
            justify-content: center;
            align-items: center;
        }

        .loading-indicator-spacer{
            width: 0;
            height: 0;
            position: relative;
            left: -18px;
            top: -18px;
        }
    }

    .loading-indicator-content{
        &.is-loading{
            //visibility: hidden;
            opacity: 0.2;
            filter: blur(2px);
        }
    }
}
