.help-icon {
    font-size: 16px;
    display: inline-block;
    color: $primary;
    margin-left: 0.1em;
    height: 0;
    transform: scale(0.9, 0.9) translate(0, 4px);
    opacity: 0.9;
    cursor: pointer;

    :hover{
        color: darken($primary, 5%);
    }

    &.help-icon-table{
        position: absolute;
        transform: scale(0.65) translate(2px, -2px);
        opacity: 0.7;
    }
}