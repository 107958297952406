.legal{
    background-color: $white;
    padding: 2em;
    text-align: center;
    font-size: 0.875em;
    opacity: 0.8;
    line-height: 1.5em;
    background-color: $white;
    
    .container{
        max-width: 1440px;
    }

    p:last-child{
        margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) { 
        font-size: 0.76em;
        padding: 0em;
        padding-top: 1.5em;
        padding-bottom: 2em;
    }

    // HACK: SHOW FOR IE11
    &.collapse:not(.show){
        display: block;
    }
    @supports (display: grid) {
        &.collapse:not(.show){
            display: none;
        }
    }
    
}