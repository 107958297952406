.FinalPriceSectionSaveEdit{

    .button-container{
        margin-top: 6px;

        @include media-breakpoint-down(md){
            margin-top: 5px;
            padding-bottom: 10px;
        }
    }   

    .custom-button{
        margin-top: 0px;
        font-size: 0.95em;
        width: 100%;
        text-align: left;
        margin-bottom: 6px;
        padding-left: 18px;

        @include media-breakpoint-down(md){
            margin-bottom: 5px;
        }
    }


    .icon-right{
        color: $primary;

        @include media-breakpoint-down(md) {
            position: relative;
            right: 10px;
        }
    }


}