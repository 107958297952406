.specified-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid $gray-300;

    padding: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;

    margin-bottom: 0.15em;

    &:last-of-type{
        margin-bottom: 2em;
    }

    .specified-item-info-category{
        font-size: 1.2em;
        font-weight: 900;
        white-space: nowrap;
    }

    .specified-item-info-description{
        font-size: 0.9em;
    }

    .specified-item-info-description{
        white-space: nowrap;
    }

    .specified-items-buttons{
        display: flex;
        align-items: center;

        .specified-items-button{
            display: flex;
            align-items: center;
            cursor: pointer;
            color: $primary;
        }

        @include media-breakpoint-down(sm){
            flex-direction: column;
            align-items: flex-start;
        }

        .specified-item-icon-text{
            font-size: 0.9em;
        }
        
        .specified-item-icon{
            padding-right: 0.4em;
        }
        .specified-item-divider{
            height: 20px;
            width: 1px;
            margin-left: 0.9em;
            margin-right: 0.9em;
            background-color: $gray-400;

            @include media-breakpoint-down(sm){
                display:none;
            }
        }
    }
    
}