
.ribbon-container{
    width: 40%;
    text-align: center;
    position: relative;
    top:6px;
    margin-right: 10px;

    .final-price-ribbon-yearly{
        font-weight: 900;
        color: $success;
        position: relative;
        left: -31px;
        top: 14px;
        font-size: 1.35em;

        @include media-breakpoint-up(lg) { 
            left: -42px;
            font-size: 1.5em;
        }
    }

    @include media-breakpoint-down(md) { 
        width: 30%;
        top:10px;
    }

    .ribbon-text-container{
        position: relative;
        @include media-breakpoint-down(md) { 
            padding-left: 0px;
        }
    }

    .price{
        //width: 33%;
        position: relative;
        margin-bottom: 0 !important;
        justify-content: center;

        @include media-breakpoint-down(sm) { 
            justify-content: center;
        }
        //top: 0.175em;
        //left: 0.1em;
        //margin-left: 0.5em;
        /*
        transform: scale(1.25, 1.25);

        @include media-breakpoint-down(md) { 
            transform: scale(1, 1);
        }

        @include media-breakpoint-down(sm) { 
            transform: scale(1.25, 1.25);
        }
        */
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        width: calc(100vw - 40px); 
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .ribbon:nth-child(1){
        width: 108%;
        height: 99px;

        @include media-breakpoint-down(md) { 
            width: calc(100% + 40px);
            height: 78px;

            .ribbon-text-container{
                left: 18px;

                @include media-breakpoint-down(md) { 
                    left: 10px;
                }
            }
        }

        @include media-breakpoint-down(xs) { 
            width: calc(100% + 20px);

            .ribbon-text-container{
                left: 22px;
            }
        }
    }

    .ribbon:nth-child(2){
        height: 50px;
        @include media-breakpoint-down(md) { 
            width: calc(100% + 20px);
            font-size: 0.9em;
            letter-spacing: -0.05em;
        }

        @include media-breakpoint-down(xs) { 
            width: calc(100% + 0px);
            font-size: 1.0em;
            .ribbon-text-container{
                left: 32px;
            }
            //letter-spacing: inherit;
        }
    }
}

